import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminFundingRequest from "./screens/Admin/FundingRequest";
import AdminTransactions from "./screens/Admin/Transactions";
import AdminDebtors from "./screens/Admin/Debtors";
import AdminUsers from "./screens/Admin/Users";
import Index from "./screens/Index";
import Invited from "./screens/Invited";
import Login from "./components/Login";
import ForgetPassword from "./screens/ForgetPassword";
import SignUp from "./screens/SignUp";
import Updates from "./screens/Updates";
import Invoice from "./screens/Invoice";
import Admins from "./screens/Admin/Admins";
import NotFound from "./screens/NotFound";
import DebtorProfile from "./screens/Admin/Debtor/Profile";
import DebtorPayment from "./screens/Admin/Debtor/Payment";
import DebtorConnectedClients from "./screens/Admin/Debtor/ConnectedClients";
import DebtorInvoices from "./screens/Admin/Debtor/Invoices";
import DebtorSimilarDebtors from "./screens/Admin/Debtor/SimilarDebtors";
import UserProfile from "./screens/Admin/User/Profile";
import FinancesLineItems from "./screens/Admin/Finances/LineItems";
import FinancesPayments from "./screens/Admin/Finances/Payments";
import FinancesPurchases from "./screens/Admin/Finances/Purchases";
import FinancesReceivables from "./screens/Admin/Finances/Receivables";
import FinancesDebtorBalances from "./screens/Admin/Finances/DebtorBalances";
import FactorClients from "./screens/Admin/FactorClients";
import FactorClientProfile from "./screens/Admin/FactorClient/Profile";
import FactorClientFundingRequests from "./screens/Admin/FactorClient/FundingRequests";
import FactorClientConnectedDebtors from "./screens/Admin/FactorClient/ConnectedDebtors";
import FactorClientConnectedCarriers from "./screens/Admin/FactorClient/ConnectedCarriers";
import FactorClientConnectDebtor from "./screens/Admin/FactorClient/ConnectDebtor";
import Wallet from "./screens/Admin/FactorClient/Wallet";
import Dashboard from "./screens/Admin/Dashboard";
import BankAccount from "./screens/BankAccount";
import Groups from "./screens/Admin/Groups/Groups";
import GroupsInvitedCarriers from "./screens/Admin/Groups/InvitedCarriers";
import GroupConnectedDebtor from "./screens/Admin/Group/ConnectedDebtor";
import GroupFundingRequests from "./screens/Admin/Group/FundingRequests";
import GroupMembers from "./screens/Admin/Group/Members";
import GroupOwnership from "./screens/Admin/Group/Ownership";
import PaymentProfile from "./screens/Admin/Payment/Profile";
import PaymentFundingRequests from "./screens/Admin/Payment/FundingRequests";
import PaymentConnectedBrokers from "./screens/Admin/Payment/ConnectedBrokers";
import PaymentPurchases from "./screens/Admin/Payment/Purchases";
import PaymentProfileReceivables from "./screens/Admin/Payment/Receivables";
import SimilarPaymentProfiles from "./screens/Admin/Payment/SimilarPaymentProfiles";
import FactoringCompanies from "./screens/Admin/Groups/FactorCompanies";
import FactoringCompanyProfile from "./screens/Admin/FactorCompany/Profile";
import FactoringCompanyConnectedCarriers from "./screens/Admin/FactorCompany/ConnectedCarriers";
import FactorCompanyPurchases from "./screens/Admin/FactorCompany/Purchases";
import Receivables from "./screens/Admin/FactorClient/Receivables";
import ResetPassword from "./screens/ResetPassword";
import InvitedCarriers from "./screens/Dashboard/ICPortal";
import Boarding from "./screens/Dashboard/ICPortal/Boarding";
import relationshipCoBrokering from "./screens/Admin/Groups/CoBrokering";

const factoringCompany = [
  <Route
    exact
    path="/admin/companies/:id/profile"
    component={FactoringCompanyProfile}
  />,
  <Route
    exact
    path="/admin/companies/:id/connected_carriers"
    component={FactoringCompanyConnectedCarriers}
  />,
  <Route exact path="/admin/companies" component={FactoringCompanies} />,
  <Route
    exact
    path="/admin/companies/needs_review"
    component={FactoringCompanies}
  />,
  <Route
    exact
    path="/admin/companies/approved"
    component={FactoringCompanies}
  />,
  <Route
    exact
    path="/admin/companies/:id/purchases"
    component={FactorCompanyPurchases}
  />
];

const group = [
  <Route
    exact
    path="/admin/groups/:id/connected_debtor"
    component={GroupConnectedDebtor}
  />,
  <Route
    exact
    path="/admin/groups/:id/funding_requests"
    component={GroupFundingRequests}
  />,
  <Route exact path="/admin/groups/:id/members" component={GroupMembers} />,
  <Route exact path="/admin/groups/:id/ownership" component={GroupOwnership} />
];

const groups = [
  <Route exact path="/admin/groups" component={Groups} />,
  <Route
    exact
    path="/admin/groups/invited_carriers"
    component={GroupsInvitedCarriers}
  />,
  <Route
    exact
    path="/admin/groups/co_brokering/"
    component={relationshipCoBrokering}
  />,
];

const debtor = [
  <Route exact path="/admin/debtors/:id/profile" component={DebtorProfile} />,
  <Route
    exact
    path="/admin/debtors/:id/receivables"
    component={DebtorPayment}
  />,
  <Route
    exact
    path="/admin/debtors/:id/funding_requests"
    component={DebtorInvoices}
  />,
  <Route
    exact
    path="/admin/debtors/:id/connected_clients"
    component={DebtorConnectedClients}
  />,
  <Route
    exact
    path="/admin/debtors/:id/similar_debtors"
    component={DebtorSimilarDebtors}
  />
];

const debtors = [
  <Route exact path="/admin/debtors" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/needs_review" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/declined" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/pending" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/approved" component={AdminDebtors} />,
  <Route
    exact
    path="/admin/debtors/verify_duplicate"
    component={AdminDebtors}
  />,
  <Route exact path="/admin/debtors" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/needs_review" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/declined" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/pending" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/approved" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/do_not_contact" component={AdminDebtors} />,
  <Route exact path="/admin/debtors/noa_panel" component={AdminDebtors} />,
  <Route
    exact
    path="/admin/debtors/verify_duplicate"
    component={AdminDebtors}
  />
];

const transactions = [
  <Route
    exact
    path="/admin/fundingrequest/:id"
    component={AdminFundingRequest}
  />,
  <Route exact path="/admin/transactions" component={AdminTransactions} />,
  <Route
    exact
    path="/admin/transactions/new_noa"
    component={AdminTransactions}
  />,
  <Route exact path="/admin/transactions/new" component={AdminTransactions} />,
  <Route
    exact
    path="/admin/transactions/fuel_advance"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/pending"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/pending_noa"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/non_factored"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/special_pending"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/pending_originals"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/pending_delivery"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/document_issue"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/cf_review"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/incomplete"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/document_review"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/approved"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/remote_approved"
    component={AdminTransactions}
  />,
  <Route
    exact
    path="/admin/transactions/declined"
    component={AdminTransactions}
  />,
  <Route exact path="/admin/transactions/paid" component={AdminTransactions} />
];

const finances = [
  <Route
    exact
    path="/admin/finances/debtor_balances"
    component={FinancesDebtorBalances}
  />,
  <Route
    exact
    path="/admin/finances/receivables"
    component={FinancesReceivables}
  />,
  <Route exact path="/admin/finances/payments" component={FinancesPayments} />,
  <Route
    exact
    path="/admin/finances/purchases"
    component={FinancesPurchases}
  />,
  <Route
    exact
    path="/admin/finances/line_items"
    component={FinancesLineItems}
  />
];

const payment = [
  <Route exact path="/admin/payment/:id/profile" component={PaymentProfile} />,
  <Route
    exact
    path="/admin/payment/:id/funding_requests"
    component={PaymentFundingRequests}
  />,
  <Route
    exact
    path="/admin/payment/:id/connected_brokers"
    component={PaymentConnectedBrokers}
  />,
  <Route
    exact
    path="/admin/payment/:id/purchases"
    component={PaymentPurchases}
  />,
  <Route
    exact
    path="/admin/payment/:id/similar_payment_profiles"
    component={SimilarPaymentProfiles}
  />,
  <Route
    exact
    path="/admin/payment/:id/receivables"
    component={PaymentProfileReceivables}
  />
];

const factorclients = [
  <Route exact path="/admin/factorclients/lead_in" component={FactorClients} />,
  <Route exact path="/admin/factorclients/new" component={FactorClients} />,
  <Route
    exact
    path="/admin/factorclients/document_review"
    component={FactorClients}
  />,
  <Route
    exact
    path="/admin/factorclients/document_issue"
    component={FactorClients}
  />,
  <Route exact path="/admin/factorclients/pending" component={FactorClients} />,
  <Route
    exact
    path="/admin/factorclients/contract_out"
    component={FactorClients}
  />,
  <Route
    exact
    path="/admin/factorclients/ucc_hold"
    component={FactorClients}
  />,
  <Route exact path="/admin/factorclients/no_auth" component={FactorClients} />,
  <Route
    exact
    path="/admin/factorclients/approved"
    component={FactorClients}
  />,
  <Route
    exact
    path="/admin/factorclients/declined"
    component={FactorClients}
  />,
  <Route exact path="/admin/factorclients/dead" component={FactorClients} />,
  <Route exact path="/admin/factorclients/" component={FactorClients} />
];

const factorclient = [
  <Route
    exact
    path="/admin/factorclients/:id/profile"
    component={FactorClientProfile}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/funding_requests"
    component={FactorClientFundingRequests}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/connected_debtors"
    component={FactorClientConnectedDebtors}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/connected_carriers"
    component={FactorClientConnectedCarriers}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/connect_debtor"
    component={FactorClientConnectDebtor}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/receivables"
    component={Receivables}
  />,
  <Route
    exact
    path="/admin/factorclients/:id/wallet"
    component={Wallet}
  />
];

const users = [
  <Route exact path="/admin/users" component={AdminUsers} />,
  <Route exact path="/admin/users/my_users" component={AdminUsers} />
];

const user = [
  <Route exact path="/admin/users/:userId/profile" component={UserProfile} />
];

const routes = (): JSX.Element => (
  <Switch>
    <Route exact path="/" component={Index} />
    <Route exact path="/admin/login" render={props => <Login userType="admin" {...props} />} />
    <Route exact path="/admin/admins" component={Admins} />
    <Route exact path="/admin/dashboard/" component={Dashboard} />
    <Route
      exact
      path="/dashboard/ic_portal/:groupId?"
      component={InvitedCarriers}
    />
    <Route
      exact
      path="/dashboard/ic_portal/invited/:token"
      component={Boarding}
    />
    {user}
    {debtor}
    {debtors}
    {transactions}
    {finances}
    {factorclients}
    {factorclient}
    {users}
    {group}
    {groups}
    {payment}
    {factoringCompany}
    <Route exact path="/invited/:token" component={Invited} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/forgetpassword" component={ForgetPassword} />
    <Route
      exact
      path="/forgetpassword/reset/:token"
      component={ResetPassword}
    />
    <Route exact path="/signup/:userType" component={SignUp} />
    <Route exact path="/signup/:userType/:token" component={SignUp} />
    <Route exact path="/update/:step" component={Updates} />
    <Route exact path="/invoice/:token" component={Invoice} />
    <Route exact path="/bank/:target" component={BankAccount} />
    <Route component={NotFound} noindex="true"/>
  </Switch>
);

export default routes;
