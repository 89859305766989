import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  List,
  Switch,
  Typography
} from "@material-ui/core";
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { Backup } from "@material-ui/icons";
import API from "../../../../../api";
import Card from "../../../../../components/Card";
import BoldInput, {
  CheckBox,
  Pennies,
  Select
} from "../../../../../components/inputs/BoldInput";
import ListItem from "../../../../../components/ListItem";
import {
  Attachment,
  Attachments
} from "../../../../../components/AttachmentsCard";
import Can from "../../../../../components/Can";
import Source from "../../../../../components/icons/SourceIcon";
import AttachmentButton from "../../../../../components/buttons/AttachmentButton";
import DocumentIssueTable from "./DocumentIssueTable";
import statuses from "./statuses";
import formatDate from "../../../../../helpers/format/formatDate";
import { fetchTerms } from "../../../../../modules/factoringClient";
import AdminDropDown from "../../../../../components/AdminDropDown";
import SendReminder from "../../../Transactions/components/SendReminder";
import { getUserType } from "../../../../../modules/auth";
import EmailFRTemplates from "../EmailFRTemplates";
import SendTextReminder from "../SendTextReminder";
import { openDialog } from "../../../../../modules/ui";
import ProfileSection from "../../../../../components/ProfileSection";
import BackgroundSpinner from "../../../../../components/BackgroundSpinner";
import FundingRequestTypeSelect from "../../../../../components/inputs/FundingRequestTypeSelect";
import { useUpdateEffect } from "../../../../../helpers/hooks";
import find from "lodash/find";

export interface Category {
  key: string;
  name: string;
}

interface Props {
  factoringMinimumFee: number;
  userType: string;
  currentUserId: string;
  nonFactoredType: string;
  factoringClientId: string;
  status: string;
  statusNote: string;
  notesFromClient: string;
  invoiceNotes: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: ChangeEventHandler<HTMLInputElement>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  missingBOL: boolean;
  missingRateCon: boolean;
  missingReceipt: boolean;
  blurryBOL: boolean;
  blurryRateCon: boolean;
  blurryReceipt: boolean;
  rate: number;
  fundedAt: string;
  salesRep: string;
  receivableStatus: string;
  transactionRep: string;
  source: string;
  attachments: any[];
  factoringDiscountRate: string;
  values: {
    attachments: Attachment[];
    invoiceAmount: number;
    debtor: {
      id: string;
    };
    paymentProfile: {
      id: string;
    };
    factored: boolean;
    carrierPayAmount: number;
    payoutDays: string;
    factoringFeeSplitRate: string;
    discountRate: number | string;
    paymentProfileDiscountRate: number | string;
    factoringDiscountRate: string;
    applyReserveHoldback: boolean;
    selfFinanceRequested: boolean;
    contractType: string;
  };
  handleDeleteAttachment: (id: string) => void;
  attachmentCategories: Category[];
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  collatedDoc: any;
  invoiceDoc: any;
  carrierInvoiceDoc?: any;
  paymentProfileId?: string;
  requestedAt: string;
  currentStatusAt: string;
  payoutDays?: string;
  sortByCategory?: Function;
  factoringID?: string;
  collatedPdf?: {
    error: boolean;
  };
  invoiceDate: string;
  factoringFeeSplitRate: string;
  fundingRequestID: string;
  applyReserveHoldback: boolean;
  enableReserveHoldbackHybrid: boolean;
  allowSelfFinanceFR: boolean;
  factoringCompany: {};
  editablePaymentSpeed: boolean;
}

interface paymentTermsProps {
  text: string;
  value: string;
  discount_rate: number;
}

const StatusInfoCard = ({
  factoringMinimumFee,
  nonFactoredType,
  factoringClientId,
  status,
  statusNote,
  notesFromClient,
  invoiceNotes,
  handleChange,
  handleBlur,
  setFieldValue,
  missingBOL,
  missingRateCon,
  missingReceipt,
  blurryBOL,
  blurryRateCon,
  blurryReceipt,
  rate,
  fundedAt,
  salesRep,
  receivableStatus,
  transactionRep,
  source,
  attachments,
  values,
  handleDeleteAttachment,
  attachmentCategories,
  setFieldTouched,
  collatedDoc,
  invoiceDoc,
  paymentProfileId,
  requestedAt,
  currentStatusAt,
  payoutDays = "",
  sortByCategory,
  factoringID,
  collatedPdf,
  factoringDiscountRate,
  carrierInvoiceDoc,
  invoiceDate,
  factoringFeeSplitRate,
  fundingRequestID,
  userType,
  currentUserId,
  applyReserveHoldback,
  enableReserveHoldbackHybrid,
  allowSelfFinanceFR,
  factoringCompany,
  editablePaymentSpeed
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const type = useSelector(getUserType);
  const [terms, setTerms] = useState([]);
  const [discountRate, setDiscountRate] = useState(rate);
  const [factoringFeeSplitRateState, setFactoringFeeSplitRateState] = useState(
    factoringFeeSplitRate
  );
  const [paymentSpeed, setPaymentSpeed] = useState(payoutDays);
  const [loading, setLoading] = useState(false);
  const approved = status === "approved";
  const mappingData: any = {
    factoring_fee_split_rate: {
      name: "factoringFeeSplitRate",
      type: "number"
    },
    invoice_amount: {
      name: ["invoiceAmount", "debtorInvoiceAmount"],
      type: "pennies"
    },
    payment_profile_invoice_amount: {
      name: "paymentProfileInvoiceAmount",
      type: "pennies"
    },
    payment_profile_discount_rate: {
      name: "paymentProfileDiscountRate",
      type: "number"
    },
    factoring_amount: {
      name: "brokerGrossPayAmount",
      type: "pennies"
    },
    reserve_amount: {
      name: "reserveHoldback",
      type: "pennies"
    },
    discount_rate_comfreight: {
      name: "rate",
      type: "number"
    },
    payment_profile_payable: {
      name: "carrierPurchaseAmount",
      type: "pennies"
    },
    factoring_payable: {
      name: "brokerPayAmount",
      type: "pennies"
    },
    factoring_fee: {
      name: "brokerDiscountAmount",
      type: "pennies"
    },
    payment_profile_fee: {
      name: "carrierDiscountAmount",
      type: "pennies"
    }
  };

  useEffect(() => {
    async function f() {
      if (factoringClientId) {
        const t = await dispatch(fetchTerms(factoringClientId));
        setTerms(
          t
            // @ts-ignore
            .filter((ter: any) => ter.enable_factoring)
            .map((ter: any) => {
              let days = ter.payout_days;
              let text = `${days} Days  (${ter.discount_rate ||
                ter.carrier_rate}% Quick Pay Fee)`;
              if (ter.payout_days === 0) {
                days = 1;
              }
              if ((ter.discount_rate || ter.carrier_rate) === 0) {
                text += `${days} Days (Free)`;
              }
              return {
                text,
                value: `ach_${days}_day`,
                discount_rate: ter.discount_rate
              };
            })
        );
      }
    }
    f();
  }, [factoringClientId]);

  const fundingRequestCalculation = useCallback(async (data: any) => {
    try {
      const res = await API.factoring.fundingRequestCalculations(data);
      Object.keys(mappingData).map((key, index) => {
        if (res[key] || res[key] === 0) {
          const value =
            mappingData[key].type === "pennies"
              ? (res[key] || 0) / 100
              : res[key];
          if (Array.isArray(mappingData[key].name)) {
            return mappingData[key].name.map((name: string) => {
              setFieldValue(name, value);
            });
          }
          return setFieldValue(mappingData[key].name, value);
        }
      });
    } catch (e) {}
  }, []);

  const getCalculation = async (fields: any): Promise<any> => {
    setLoading(true);
    try {
      const data = {
        funding_request_id: fundingRequestID,
        invoice_amount: fields.invoiceAmount * 100,
        debtor: fields.debtor.id,
        factored: fields.factored,
        factoring: factoringClientId,
        discount_rate_comfreight: fields.discountRate,
        apply_reserve_holdback: fields.applyReserveHoldback,
        contract_type: fields.contractType,
        create_rates_revision: false,
        ...(() =>
          fields.factored
            ? {
              payment_profile_invoice_amount: fields.carrierPayAmount * 100,
              payment_speed: parseInt(
                (fields.payoutDays.match(/\d+/g) || [])[0],
                0
              ),
              payment_profile: fields.paymentProfile.id,
              factoring_fee_split_rate: fields.factoringFeeSplitRate
            }
            : {})()
      };
      await fundingRequestCalculation(data);
    } catch (err) {
    }
    setLoading(false);
  }

  useUpdateEffect(() => {
    async function f() {
      if (parseInt(values.factoringFeeSplitRate, 0) >= 0) {
        await getCalculation(values);
      }
    }
    f();
  }, [factoringFeeSplitRateState]);

  useUpdateEffect(() => {
    async function f() {
      if (!isUndefined(values.discountRate)) {
        await getCalculation(values);
      }
    }
    f();
  }, [discountRate]);

  useUpdateEffect(() => {
    async function f() {
      if (!isUndefined(values.applyReserveHoldback)) {
        await getCalculation(values);
      }
    }
    f();
  }, [applyReserveHoldback]);

  useUpdateEffect(() => {
    async function f() {
      if (!isUndefined(values.payoutDays)) {
        await getCalculation(values);
      }
    }
    f();
  }, [paymentSpeed]);

  return (
    <Card>
      {loading && <BackgroundSpinner />}
      <List>
        {paymentProfileId ? (
          <React.Fragment>
            {allowSelfFinanceFR && (
              <>
              <ListItem>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={values.selfFinanceRequested}
                        onChange={(e, checked) => {
                          setFieldValue(
                            "selfFinanceRequested",
                            !values.selfFinanceRequested
                          );
                          if (checked) {
                            setFieldValue(
                              "applyReserveHoldback",
                              false
                            );
                            setFieldValue("factoringFeeSplitRate", 100);
                            setFieldValue("payoutDays", 'ach_1_day');
                          }
                        }}
                        value="selfFinanceRequested"
                      />
                    )}
                    label="Request Self Finance"
                  />
                </FormGroup>
              </ListItem>
              <ListItem>
                <FundingRequestTypeSelect
                  fullWidth
                  value={values.contractType}
                  setFieldValue={setFieldValue}
                  name="contractType"
                  label="Funding Request Type"
                  withHeader
                />
              </ListItem>
              </>
            )}
            {enableReserveHoldbackHybrid && (
              <ListItem>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Switch
                        disabled={values.selfFinanceRequested}
                        checked={applyReserveHoldback}
                        onChange={() =>
                          setFieldValue(
                            "applyReserveHoldback",
                            !applyReserveHoldback
                          )
                        }
                        value="applyReserveHoldback"
                      />
                    )}
                    label="Enabled w/Margin Advance"
                  />
                </FormGroup>
              </ListItem>
            )}
            <ListItem>
              <Select
                label="Payment Speed"
                name="payoutDays"
                value={values.payoutDays}
                fullWidth
                selections={terms}
                onBlur={handleBlur}
                onChange={(event: any) => {
                  let paymentTerm: paymentTermsProps = find(terms, ["value", event.target.value])!;
                  setFieldValue("discountRate", paymentTerm.discount_rate);
                  setFieldValue("payoutDays", event.target.value);
                  setPaymentSpeed(event.target.value);
                }}
                disabled={approved && !editablePaymentSpeed}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                label="Client Base Discount Rate"
                disabled
                value={values.factoringDiscountRate}
                fullWidth
                name="factoringDiscountRate"
              />
            </ListItem>
            <ListItem>
              <Select
                label="Transaction Discount Rate"
                fullWidth
                onBlur={handleBlur}
                onChange={(event: any) => {
                  setDiscountRate(event.target.value);
                  setFieldValue("discountRate", event.target.value);
                }}
                // disabled
                name="discountRate"
                value={values.discountRate}
                selections={[
                  {
                    text: "0.75%",
                    value: 0.75
                  },
                  {
                    text: "1%",
                    value: 1
                  },
                  {
                    text: "1.25%",
                    value: 1.25
                  },
                  {
                    text: "1.5%",
                    value: 1.5
                  },
                  {
                    text: "1.75%",
                    value: 1.75
                  },
                  {
                    text: "2%",
                    value: 2
                  },
                  {
                    text: "2.25%",
                    value: 2.25
                  },
                  {
                    text: "2.5%",
                    value: 2.5
                  },
                  {
                    text: "2.75%",
                    value: 2.75
                  },
                  {
                    text: "3%",
                    value: 3
                  },
                  {
                    text: "3.25%",
                    value: 3.25
                  },
                  {
                    text: "3.5%",
                    value: 3.5
                  },
                  {
                    text: "3.75%",
                    value: 3.75
                  },
                  {
                    text: "4%",
                    value: 4
                  },
                  {
                    text: "4.25%",
                    value: 4.25
                  },
                  {
                    text: "4.5%",
                    value: 4.5
                  },
                  {
                    text: "4.75%",
                    value: 4.75
                  },
                  {
                    text: "5%",
                    value: 5
                  }
                ]}
              />
            </ListItem>
            <ListItem>
              <BoldInput
                label="Carrier Rate"
                name="paymentProfileDiscountRate"
                disabled
                onBlur={handleBlur}
                onChange={handleChange}
                value={`${values.paymentProfileDiscountRate}%`}
                fullWidth
              />
            </ListItem>
            <ListItem>
              <Select
                disabled={!isEmpty(factoringCompany)}
                label="Broker Portion"
                value={factoringFeeSplitRate}
                fullWidth
                onBlur={handleBlur}
                onChange={(event: any) => {
                  setFactoringFeeSplitRateState(event.target.value);
                  setFieldValue("factoringFeeSplitRate", event.target.value);
                }}
                name="factoringFeeSplitRate"
                selections={[
                  {
                    text: "0%",
                    value: 0
                  },
                  {
                    text: "50%",
                    value: 50
                  },
                  {
                    text: "100%",
                    value: 100
                  }
                ]}
              />
            </ListItem>
          </React.Fragment>
        ) : (
          <ListItem>
            <BoldInput label="Rate" disabled value={rate} fullWidth />
          </ListItem>
        )}
        <ListItem>
          <Pennies
            fullWidth
            disabled
            label="Factoring Minimum Fee:"
            value={factoringMinimumFee}
            name="factoringMinimumFee"
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <Can
            perform="admin-funding-request-transaction-rep:update"
            yes={() => (
              <AdminDropDown
                name="transactionRep"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Transaction Rep"
                value={transactionRep}
                currentUserId={
                  userType === "sub_admin" ? currentUserId : undefined
                }
              />
            )}
            no={() => (
              <AdminDropDown
                name="transactionRep"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Transaction Rep"
                value={transactionRep}
              />
            )}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            fullWidth
            label="Sales Rep"
            name="salesRep"
            value={salesRep}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled
          />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Receivable Status"
            disabled
            value={receivableStatus}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Requested At"
            disabled
            value={formatDate(requestedAt)}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <BoldInput label="Funded At" disabled value={fundedAt} fullWidth />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Current Status At"
            disabled
            value={formatDate(currentStatusAt)}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <BoldInput
            label="Invoice Date"
            disabled
            value={formatDate(invoiceDate)}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <InputLabel
                shrink
                style={{
                  fontSize: 20,
                  color: "black",
                  fontWeight: 900
                }}
              >
                Source
              </InputLabel>
            </Grid>
            <Grid item>
              <Source source={source} />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <EmailFRTemplates id={fundingRequestID} />
        </ListItem>
        <ListItem>
          <ProfileSection>Status Notes</ProfileSection>
        </ListItem>
        <ListItem>
          <Select
            value={status}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            name="status"
            label="Status"
            selections={statuses
              .filter(status => {
                if (type === "factoring_remote_admin") {
                  if (
                    status.value === "special_pending" ||
                    status.value === "pending_delivery" ||
                    status.value === "approved" ||
                    status.value === "deleted" ||
                    status.value === "declined" ||
                    status.value === "paid"
                  ) {
                    return false;
                  }
                }
                return true;
              })
              .map(({ value, name }): {
                value: string;
                text: string;
              } => ({
                value,
                text: name
              }))}
          />
        </ListItem>
        {paymentProfileId && status === "non_factored" ? (
          <ListItem>
            <Select
              value={nonFactoredType}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              name="nonFactoredType"
              label="Broker Non-Factored Type"
              selections={[
                {
                  text: "Bill Out Only",
                  value: "bill_out"
                },
                {
                  value: "standard",
                  text: "Standard"
                },
                {
                  value: "payment_forward",
                  text: "Payment Forward"
                }
              ]}
            />
          </ListItem>
        ) : null}
        {status === "document_issue" && (
          <ListItem>
            <DocumentIssueTable
              setFieldValue={setFieldValue}
              missingBOL={missingBOL}
              missingRateCon={missingRateCon}
              missingReceipt={missingReceipt}
              blurryBOL={blurryBOL}
              blurryRateCon={blurryRateCon}
              blurryReceipt={blurryReceipt}
            />
          </ListItem>
        )}
        <ListItem>
          <SendReminder id={fundingRequestID} status={status} />
          <SendTextReminder
            id={fundingRequestID}
            factoringID={factoringClientId}
            status={status}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            name="statusNote"
            label="Status Note"
            multiline
            rows={3}
            value={statusNote}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            name="notesFromClient"
            label="Notes From Client"
            value={notesFromClient}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <BoldInput
            name="invoiceNotes"
            label="Invoice Notes"
            value={invoiceNotes}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ListItem>
        <ListItem>
          <ProfileSection>Attachments</ProfileSection>
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography>Collated Billing PDF</Typography>
                </Grid>
                <Grid item>
                  <AttachmentButton
                    attachments={[collatedDoc]}
                    {...collatedPdf}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography>Invoice</Typography>
                </Grid>
                <Grid item>
                  <AttachmentButton attachments={[invoiceDoc]} />
                </Grid>
              </Grid>
            </Grid>
            {!isEmpty(carrierInvoiceDoc) && (
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography>Carrier</Typography>
                  </Grid>
                  <Grid item>
                    <AttachmentButton attachments={[carrierInvoiceDoc]} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container alignItems="center" justify="center">
            <Button
              style={{
                backgroundColor: "#2196f3",
                fontSize: "14px",
                color: "white"
              }}
              onClick={() =>
                dispatch(openDialog("upload-funding-request-attachments"))
              }
            >
              Upload Documents <Backup style={{ paddingLeft: 5 }} />
            </Button>
            <Attachments
              files={attachments}
              values={values}
              handleDelete={handleDeleteAttachment}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              multipleCategories
              categories={attachmentCategories}
              sortByCategory={sortByCategory}
              requestID={factoringID}
              broker={Boolean(paymentProfileId)}
              updateDisplayName
            />
          </Grid>
        </ListItem>
      </List>
    </Card>
  );
};

export default StatusInfoCard;
